<template>
<div>
  <iframe width="100%"
          style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"
          :src="realUrl">
  </iframe>
</div>
</template>

<script>
import {getSubscriptionsByStore, getUserLastTimeLoginStore} from "@/service/firebase/user";
import {getBaseAndUrlForDeviceId} from "@/service/firebase/baseUrlSetting";

export default {
  name: "Home",
  data: function () {
    return {
      realUrl: '',
    }
  },
  async mounted () {
    const lastTimeLoginStore = await getUserLastTimeLoginStore()
    console.log(lastTimeLoginStore, 'lastTimeLoginStore')
    const res = (await getSubscriptionsByStore(lastTimeLoginStore.deviceId)).map(it => it.order.productList).flat()
    if (res.map(x => x.metadata.code).includes('restaurant')) {
      this.realUrl = 'http://km.aaden.io?Base=' + await getBaseAndUrlForDeviceId(lastTimeLoginStore.deviceId)
    } else {
      this.realUrl = 'http://kmm.aaden.io?Base=' + await getBaseAndUrlForDeviceId(lastTimeLoginStore.deviceId)
    }
    console.log(res, 'res')
  },
}
</script>

<style scoped>

</style>
